<template>
  <el-card class="body" style="margin-top: 10px;">
    <div class="itemPut">
      <div style="margin: 20px"></div>
      <el-form
        :model="expressForm"
        label-width="150px"
        label-position="right"
        :rules="rules"
        ref="inserform"
      >
        <el-form-item label="快递公司名称：" prop="expressName">
          <el-input v-model.trim="expressForm.expressName"></el-input>
        </el-form-item>
        <el-form-item label="快递公司缩写：" prop="expressCode">
          <el-input v-model.trim="expressForm.expressCode"></el-input>
        </el-form-item>
        <el-form-item label="配送设置：" prop="info" class="labelinfo">
          <div class="info">
            <div class="timer">
              <div>当日达截止时间</div>
              <el-time-picker
                v-model="expressForm.dueTime"
                :picker-options="{
                  selectableRange: '00:01:00 - 23:59:00',
                }"
                placeholder="任意时间点"
                value-format="HH:mm"
              >
              </el-time-picker>
            </div>
            <div class="detail">
              <div class="item">
                <div>首件(kg)</div>
                <el-input
                  v-model="expressForm.firstWeight"
                  style="width: 120px"
                ></el-input>
              </div>
              <div class="item">
                <div>运费(元)</div>
                <el-input
                  v-model="expressForm.baseFree"
                  style="width: 120px"
                ></el-input>
              </div>
              <div class="item">
                <div>续件(kg)</div>
                <el-input
                  v-model="expressForm.stepwiseWeight"
                  style="width: 120px"
                ></el-input>
              </div>
              <div class="item">
                <div>运费(元)</div>
                <el-input
                  v-model="expressForm.additionalFee"
                  style="width: 120px"
                ></el-input>
              </div>
              <div class="item">
                <div>重量上限(kg)</div>
                <el-input
                  v-model="expressForm.upperWeight"
                  style="width: 120px"
                ></el-input>
              </div>
              <div class="item">
                <div>重量下限(kg)</div>
                <el-input
                  v-model="expressForm.lowerWeight"
                  style="width: 120px"
                ></el-input>
              </div>
            </div>
          </div>
        </el-form-item>
         <el-form-item label="是否自运：" prop="selfShipment">
          <el-radio v-model="expressForm.selfShipment" :label="false">否</el-radio>
          <el-radio v-model="expressForm.selfShipment" :label="true">是</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <router-link to="/setting/express/list">
          <el-button>取 消</el-button>
        </router-link>
        <el-button
          type="primary"
          style="margin-left: 50px"
          @click=" expressEdit"
          >提 交</el-button
        >
      </div>
    </div>
  </el-card>
</template>
<script>
import { expressEdit,expressEditId } from "@/api/express";
export default {
  data() {
    return {
      expressForm: {
        expressName: "", //快递名称
        dueTime: "", //当日达截止时间
        expressCode: "", //快递编码
        firstWeight: "", //首件
        baseFree: "", //基础运费
        stepwiseWeight: "", //续件重量
        additionalFee: "", //续重运费
        upperWeight: "", //重量上线
        lowerWeight: "", //重量下线
        selfShipment: null,
      },
      rules: {
        expressName: [
          { required: true, message: "请填写物流名称", trigger: "blur" },
        ],
        expressCode: [
          { required: true, message: "请填写物流名称缩写", trigger: "blur" },
        ],
         selfShipment:[
          { required: true, message: "请选择是否自运", trigger: "blur" },
        ]
      },
    };
  },
  created() {
    this.list();
  },
  methods: {
    //获取详情
    list() {
      expressEditId({id:this.$route.query.id}).then(res=>{
        if(res.status==10000){
          this.expressForm = res.data
        }
      })
    },
    //编辑
    expressEdit() {
      this.$refs.inserform.validate((res) => {
        if (res) {
          if (
            this.expressForm.dueTime != "" &&
            this.expressForm.firstWeight != "" &&
            this.expressForm.baseFree != "" &&
            this.expressForm.stepwiseWeight != "" &&
            this.expressForm.additionalFee != "" &&
            this.expressForm.upperWeight != "" &&
            this.expressForm.lowerWeight != ""
          ) {
            if (
              this.expressForm.lowerWeight > 0 &&
              this.expressForm.firstWeight > 0 &&
              this.expressForm.baseFree > 0 &&
              this.expressForm.stepwiseWeight > 0 &&
              this.expressForm.additionalFee > 0 &&
              this.expressForm.upperWeight > 0
            ) {
              if (this.expressForm.dueTime.length < 8) {
                let obj = {
                  ...this.expressForm,
                  dueTime: this.expressForm.dueTime + ":00",
                };
                 expressEdit(obj).then((res) => {
                if (res.status == 10000) {
                  this.$message.success("添加成功");
                  this.$router.push("/setting/express/list");
                }
              });
              }else{
                let obj = {
                  ...this.expressForm,
                  dueTime: this.expressForm.dueTime,
                };
                 expressEdit(obj).then((res) => {
                if (res.status == 10000) {
                  this.$message.success("添加成功");
                  this.$router.push("/setting/express/list");
                }
              });
              }
            } else {
              this.$message.error("数值不可以小于零");
            }
          } else {
            this.$message.error("请填写配送设置");
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/css/overall.less";
.info {
  width: 800px;
  display: flex;
  .timer {
    margin-right: 40px;
    text-align: center;
  }
  .detail {
    display: flex;
    .item {
      margin-right: 20px;
      text-align: center;
    }
  }
}
.labelinfo {
  /deep/ .el-form-item__label {
    margin-top: 40px;
  }
}
</style>